import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector,} from 'react-redux';
// // --- support / const
import { getTodayMonth } from '../../../../utils/Date/SupportDate';
import * as bookingApi from '../../../../api/booking';

import './guestNumber.css'
import ModalNotify from '../../../../features/ModalNotify';
import { unitsAction } from '../../../../redux/actions'; //authAction, modalAction,

function GuestNumberContainer() {
    //let history = useHistory();
    const dispatch = useDispatch();
    const [guestNumberData, setGuestNumberData] = useState({
        branchInformation: {
            name: "",
            room: 0,
        },
        content: [],
        roomTotal: 0,
        month: getTodayMonth(),
        isLoading: true,
    });
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { branchID, role, area, } = auth.user;

    useEffect(()=>{
        const callAPI = async () => {
            dispatch(unitsAction.showLoading());
            const result = await bookingApi.findGuestNumber(branchID, guestNumberData.month, token);
            console.log("result", result);
            
            setGuestNumberData({
                branchInformation: result.data.branchInformation,
                roomTotal: result.data.roomTotal,
                femaleTotal: result.data.femaleTotal,
                content: result.data.content,
                month: guestNumberData.month,
                isLoading: false,
            })
            dispatch(unitsAction.hiddenLoading());
        }
        if(branchID) callAPI();
    },[guestNumberData.month, branchID])

    // Render
    function renderGuestNumber(start, end) {
        // render to 30
        var result = [];
        for (let i = start; i <= end; i++) {
            if (i === start) {
                result.push(<div>
                    <div className="bg_yellow sticky_title man-show-data-list_item" style={{width:'120px',minWidth:'120px',maxWidth:'120px'}}>Date</div>
                    <div className="man-show-data-list_item"><i class="fa-solid fa-bed"></i> <span>Stay Guest</span></div>
                    <div className="man-show-data-list_item"><i class="fa-solid fa-right-to-bracket"></i> <span>Check In</span></div>
                    <div className="man-show-data-list_item"><i class="fa-solid fa-right-from-bracket"></i> <span>Check Out</span></div>

                    <div className="man-show-data-list_item"><i class="fa-solid fa-venus"></i> <span>Female</span></div>
                    <div className="man-show-data-list_item"><i class="fa-solid fa-bolt"></i> <span>Short Time</span></div>
                    <div className="man-show-data-list_item"><i class="fa-regular fa-clock"></i> <span>Late out</span></div>
                    <div className="man-show-data-list_item"><i class="fa-solid fa-percent"></i> <span>Capacity</span></div>
                </div>)
            } else {
                const calculatorCap = guestNumberData.content[i - 1].guestNumber / guestNumberData.branchInformation.room;
                //guestNumberData.content[i - 1].list_short.toString()

                result.push(<div>
                    <div className="bg_yellow sticky_title man-show-data-list_item"
                        style={{ minWidth: '75px', maxWidth: '75px' }}>{i}</div>
                    <div className="man-show-data-list_item">{guestNumberData.content[i - 1].guestNumber}</div>
                    <div className="man-show-data-list_item">{guestNumberData.content[i - 1].check_in}</div>
                    <div className="man-show-data-list_item">{guestNumberData.content[i - 1].check_out}</div>
                    <div className="man-show-data-list_item">{guestNumberData.content[i - 1].female}</div>

                    <div className="man-show-data-list_item guest-name_short-time">
                        <div className="guest-name_short-time_count">{guestNumberData.content[i - 1].short_time}</div>
                        <div className="guest-name_short-time_list bg_yellow">{guestNumberData.content[i - 1].list_short.toString()}</div>
                    </div>
                    <div className="man-show-data-list_item guest-name_short-time">
                        <div className="guest-name_short-time_count">
                            {guestNumberData.content[i - 1].late_out === undefined ? 0 : guestNumberData.content[i - 1].late_out}
                        </div>
                        <div className={guestNumberData.content[i - 1].list_lateOut === undefined ? "op-hidden" :
                            guestNumberData.content[i - 1].list_lateOut.length === 0 ? "op-hidden" : "guest-name_short-time_list bg_yellow"}>
                            ({guestNumberData.content[i - 1].list_lateOut === undefined ? [] : guestNumberData.content[i - 1].list_lateOut.toString()})
                        </div>
                    </div>
                    <div className={(calculatorCap >= 0.5 ? "op-redcolor" : "") + " man-show-data-list_item"}>
                        {Math.round(calculatorCap * 10000) / 100}%
                    </div>
                </div>)
            }
        }
        return result;
    }

    return (
        <React.Fragment>
            <ModalNotify />
            <h1>Find Guest Number On Month</h1>
            <ul className="nav nav-sub d-flex">
            </ul>
            <br></br>
            {
                !guestNumberData.isLoading ? <div className="man-show-data-list mt-3"
                    style={{ marginLeft: '50px' }}>
                    <div className='d-flex justify-content-around'>
                        <div>
                            <h2>Branch Information</h2>
                            <h4>* Name : Azumaya {guestNumberData.branchInformation.name}</h4>
                            <h4>* Total Room: {guestNumberData.branchInformation.room}</h4>

                            <input
                                type="month"
                                //className="nav-link-sub"
                                href="#"
                                onChange={e => setGuestNumberData(
                                    {
                                        ...guestNumberData,
                                        month: e.target.value
                                    })}
                                value={guestNumberData.month}
                            />
                        </div>

                        <div>
                            <h2>Report Data On Month:</h2>
                            <h4>* Total Room: {guestNumberData.roomTotal}</h4>
                            <h4>* Capacity: {Math.round(guestNumberData.roomTotal / (guestNumberData.branchInformation.room * guestNumberData.content.length)*10000)/100}%</h4>
                            <h4>* Female: {guestNumberData.femaleTotal} guests</h4>
                            {/* <h4>*</h4> */}
                        </div>
                    </div>

                    <div className={"d-flex mt-3 justify-content-center"}>
                        {renderGuestNumber(0, 15)}
                    </div>
                    <div>----</div>
                    <div className={"d-flex mt-3 justify-content-center"}>
                        {renderGuestNumber(15, guestNumberData.content.length)}
                    </div>
                </div> : ""
            }

        </React.Fragment >
    );

}

export default GuestNumberContainer;
