import React, { useEffect, useState } from 'react';
import * as branchApi from '../../../api/branch'

// import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearch, faUser } from "@fortawesome/free-solid-svg-icons"; //faWallet, faFileInvoiceDollar, faQrcode, faNewspaper
import './supportTools.css'
import { useSelector } from 'react-redux';

function SupportToolsComponent() {
    const user = useSelector(state => state.auth.user);
    const token = useSelector(state => state.token);
    const { role, branchID } = user;
    const [rate, setRate] = useState(0);

    function myFunction(link) {
        window.open(link);
    }

    useEffect(() => {
        // check rate
        const takeRate = async () => {
            const dataRate = await branchApi.takeExchangeRate(branchID);
            setRate(dataRate.data.data.rateYen);
        }

        if(branchID) {
            takeRate();
        }
        // setRate(190);
    },[branchID])


    function updateExChangeRate(value) {
        const updateRate = async () => {
          //  const dataUpdate = 
            await branchApi.updateExChangeRate(branchID, {value}, token);
        }

        updateRate();
    }

    return (
        <div className="support_tools scrollbar" id="style-4">
            <div className="d-flex p-2">
                <h2 className="support_tools__title">Support Tools </h2>
            </div>
            <p style={{ borderBottom: '1px solid #999' }}></p>
            <ul className="support_tools__list">
                {/* <li className="support_tools__list__item mb-3 d-flex"
                  onClick={() => myFunction("/calculated_vat")}>
                    <div to="/calculated_vat" className="support_tools__list__text">Calculate VAT</div>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} className="nav-bar__icon mr-2 "
                        style={{ maxWith: '50px' }} />
                </li>

                <li className={"support_tools__list__item mb-3 d-flex"}
                    onClick={() => myFunction("/calculated_rc_wallet")}>
                    <div className="support_tools__list__text">Reception Wallet</div>
                    <FontAwesomeIcon icon={faWallet} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>

                <li className="support_tools__list__item mb-3 d-flex"
                    onClick={() => myFunction("/find_bill/rcsum")}>
                    <div className="support_tools__list__text">Find Bill on RC Sum</div>
                    <FontAwesomeIcon icon={faSearch} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li> */}

                {/* <li className={role === 4 ? "support_tools__list__item mb-3 d-flex" : "op-hidden"}
                    onClick={() => myFunction("/find_bill/accsum")}>
                    <div className="support_tools__list__text">Find Bill on Acc Sum</div>
                    <FontAwesomeIcon icon={faSearch} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li> */}

                {/* <li className={"support_tools__list__item mb-3 d-flex"}>
                    <div style={{paddingLeft:'24px'}}>Setup Yen:</div>
                    <input style={{width:'80px'}}></input>
                </li> */}

                <li class="support_tools__list__item mb-3 d-flex">
                    <div style={{paddingLeft: '24px',width: '200px'}}>Setup Rate Yen:</div>
                    <input style={{width: '60px',marginLeft: '20px'}}
                        value={rate}
                        disabled={role !== 0}
                        onChange = {e => setRate(e.target.value)}
                        onBlur={ e => updateExChangeRate(e.target.value)}
                        maxLength={3}
                        type='number'
                    ></input>
                </li>

                <li className={"support_tools__list__item mb-3 d-flex"}
                    onClick={() => myFunction("/guest_number")}>
                    <div className="support_tools__list__text">Guest Number</div>
                    <FontAwesomeIcon icon={faSearch} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>

                <li className={"support_tools__list__item mb-3 d-flex"}
                    onClick={() => myFunction("/guest_checkin")}>
                    <div className="support_tools__list__text">Guest Check In (ACC)</div>
                    <FontAwesomeIcon icon={faUser} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>

                {(role === 0 || branchID === 99 ) && <li className={"support_tools__list__item mb-3 d-flex"}
                    onClick={() => myFunction("/setup_promotion")}>
                    <div className="support_tools__list__text">Show Promotion</div>
                    <i className="fa-solid fa-tags nav-bar__icon mr-2" style={{ maxWith: '50px' }}></i>
                    {/* <FontAwesomeIcon icon={faUser} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} /> */}
                </li>}

                {(role === 4 || role === 9) && <li className={"support_tools__list__item mb-3 d-flex"}
                    onClick={() => myFunction("/guest_checkout")}>
                    <div className="support_tools__list__text">Guest Check Out (ACC)</div>
                    <FontAwesomeIcon icon={faUser} className="nav-bar__icon mr-2 " style={{ maxWith: '50px' }} />
                </li>}
            </ul>
        </div >
    );
}

export default SupportToolsComponent;
