import React from "react";
import { useState } from "react";

function SetupPromotion() {
    const setupCss = {
        tag : {
            width:'40%', 
            padding: '4px',
             border: '1px solid', 
             cursor: "pointer"
        }
    }

    const FAKE_DATA = {
        Active: [
            {id:1, name: "Promotion 1", startDate: new Date(), endDate: new Date(), textShow: "TET",textAdd: "40% Tet promotion"},
            {id:2, name: "Promotion 2", startDate: new Date(), endDate: new Date(), textShow: "OBON",textAdd: "20% Obon"},
            {id:3, name: "Promotion 3", startDate: new Date(), endDate: new Date(), textShow: "WEEK",textAdd: "30% discount"},
        ],
        Expired: [
            {id:4, name: "Promotion 4", startDate: new Date(), endDate: new Date(), textShow: "Promotion 1",textAdd: "Promotion 1"},
            {id:5, name: "Promotion 5", startDate: new Date(), endDate: new Date(), textShow: "Promotion 1",textAdd: "Promotion 1"},
            {id:6, name: "Promotion 6", startDate: new Date(), endDate: new Date(), textShow: "Promotion 1",textAdd: "Promotion 1"}
        ]
    }

    const [tag, setTaq] = useState("Active"); // End

    function renderItem() {
        let result = [];

        FAKE_DATA[tag].forEach(item => {
            result.push(
                <tr>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.startDate.toLocaleDateString()}</td>
                    <td>{item.endDate.toLocaleDateString()}</td>
                    <td>{item.textShow}</td>
                    <td>{item.textAdd}</td>
                    <td>
                        <button className={tag === "Active" ? "btn btn-danger" : "btn btn-success"}>{tag === "Active"? "End" :"Active" }</button>
                    </td>

                </tr>
            )
        })
        return result;
    }

    return (
        <div>
            <h1>Setup Promotion</h1>

            <div className="d-flex">
                <h2 style={{...setupCss.tag, background: tag ==="Active" ? "yellow" : ""}} 
                onClick={()=> setTaq("Active")}>Active</h2>
                <h2 style={{...setupCss.tag, background: tag !=="Active" ? "yellow" : ""}} 
                onClick={()=> setTaq("Expired")}>Expired</h2>
            </div>
            <table style={{width:"80%", margin: "0 24px"}}>
                <tr>
                    <th style={{width:'20px'}}>ID</th>
                    <th>Name</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Text Show </th>
                    <th>Text Add</th>
                    <th>Action</th>
                </tr>

              { renderItem(tag) }

            </table>
        </div>
    )
}

export default SetupPromotion;