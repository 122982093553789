import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from "react-tooltip";
import { RATE_VAT } from "../../../constaints/other/otherConstaints"

ReservationRoomStyleComponent.propTypes = {
};

function ReservationRoomStyleComponent(props) {
    const { data } = props;
    const listWaiting = useSelector(state => state.reservation.listWaiting);
    const listCancel = useSelector(state => state.reservation.listCancel);
  //  const { listRoomExtend } = scheduleData;
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;
//orange
    function renderRowNameWithList(listRender, type) {
    //    console.log("listRender", listRender)
        let result = [];
        let nameReturn = "";
        const listKey = Object.values(listRender);

        function checkItemOnArray(arrayCheck) {
          //  console.log("arrayCheck", arrayCheck)
            arrayCheck.forEach(data => {
                if(data !=="") {
                    if(Array.isArray(data)) {
                        return nameReturn = data[0].name;
                    } else {
                        if("name" in data) {
                            return nameReturn = data.name;
                        }
                    }
                }
            })
            return nameReturn;
        }

        if(listKey.length >0) {
            listKey.forEach(item => {
                result.push(<div 
                    className="list-room_list-room"
                    style={{ width: '100%', background: type==="cancel" ? "#F06292" : "orange" }}>
                        {checkItemOnArray(item)}
                </div>)
            })
        }

        return result;
    }
    
    function renderRoomType() {
        let result = [];
        if ("listRoom" in data) {
            const keyOnListRoom = Object.keys(data.listRoom);
            var save = [];

            keyOnListRoom.forEach((roomType) => {
                const countRoom = data.listRoom[roomType].length;
                if (props.selectRoomType === roomType || props.selectRoomType === "All") {
                    function renderRoomChildren(dataRender) {
                        if(dataRender){
                            return (<div className={"list-room_list-room " + (!dataRender ? "op-hidden" : "")}>
                            <div>
                                {dataRender} {data.listRoomExtend[dataRender].behind}
                            </div>
                            <div style={{ fontSize: '1rem' }}>{data.listRoomExtend[dataRender].sqm} sqm</div>
                        </div>)
                        }
                    }

                    function checkCountRoomSelectFontSize() {
                        if(countRoom === 1 && roomType.slice(0, roomType.length-7) === "SEMI SUITE WITH KITCHEN ") return "1rem"
                        else if(countRoom === 1 && roomType.slice(0, roomType.length-7) === "Deluxe L with kitchen ") return "1rem"
                        else if(countRoom === 1 && roomType.slice(0, roomType.length-7) === "EXECUTIVE WITH KITCHEN ") return "1rem"
                        else if(countRoom === 1 && roomType.slice(0, roomType.length-7) === "ANNEX WITH KITCHEN ") return "1rem"
                        else if (countRoom === 1) return "1.2rem"
                        else return "1.3rem"    
                    }

                   // console.log("roomType.slice(0, roomType.length-7)", roomType.slice(0, roomType.length-7))

                   function checkRenderRoomChildren() {
                     let result = [];

                     data.listRoom[roomType].forEach((roomNumber,index) => {
                        result.push(renderRoomChildren(data.listRoom[roomType][index]))
                        // {renderRoomChildren(data.listRoom[roomType][index])}
                     })

                     return result;
                   }

                   function renderRoomTypeWithBranch() {
                       let result = [];
                   // console.log("branchID", branchID)
                       if(Number(branchID) === 11 || Number(branchID) === 97) {
                        result.push(<div className="list-room_type">
                                        <div style={{ fontSize: checkCountRoomSelectFontSize() }}>
                                                {roomType.slice(0, roomType.length-7)}</div>
                                        <div style={{ fontSize: countRoom === 1 ? '1rem' : '1.1rem' }}>
                                                {/* {Number(roomType.slice(roomType.length-7, roomType.length)).toLocaleString()} */}
                                            {(Number(roomType.slice(roomType.length-7, roomType.length-1)) / 2500).toLocaleString()}$
                                            </div>
                                    </div>)

                       } else {
                            result.push(<><div className="list-room_type"
                                    >
                                    <div style={{ fontSize: checkCountRoomSelectFontSize() , cursor: 'pointer' }}
                                        data-tip
                                        data-for={roomType}
                                    >
                                            {roomType.slice(0, roomType.length-7)}</div>
                                    <div style={{ fontSize: countRoom === 1 ? '1rem' : '1.1rem' }}>
                                            {Number(roomType.slice(roomType.length-7, roomType.length)).toLocaleString()}
                                        ({(Number(roomType.slice(roomType.length-7, roomType.length-1)) / 2500).toLocaleString()}$)
                                        </div>
                               </div>
                                {branchID === 15 && <ReactTooltip
                                     id= {roomType}
                                     type="light"
                                >  
                                   <div style={{height:"30px", paddingLeft:"4px", paddingRight:"4px"}}>
                                        {Number(roomType.slice(roomType.length-7, roomType.length)*(1 + RATE_VAT)).toLocaleString()}
                                        ({(Number(roomType.slice(roomType.length-7, roomType.length-1)*(1 + RATE_VAT)) / 2500).toLocaleString()}$){`( ${RATE_VAT*100}% VAT)`}
                                   </div>
                                </ReactTooltip>}
                            
                            </>)
                       }

                       return result;
                   }
 
                    const test = <div className={"d-flex "} >
                        {renderRoomTypeWithBranch()}
                        <div className="list-room_list">
                            {checkRenderRoomChildren()}
                        </div>
                    </div>
                    save.push(test)
                }

                result.push(save);
                save = [];
            });

        }
        return result;
    }

    function renderListRoomType() {
        var result = [];
        if ("listRoom" in data) {
            const keyOnListRoom = Object.keys(data.listRoom);
            result.push(<span className="dropdown-item"
                onClick={() => props.setSelectRoomType("All")}
                style={{ fontSize: '1.3rem', }} href="#">ALL</span>)

            keyOnListRoom.forEach((key) => {
                result.push(<span
                    className={"dropdown-item "}
                    onClick={() => props.setSelectRoomType(key)}
                    style={{ fontSize: '1.3rem', }}>{key}</span>)
            });
        }
        return result;
    }

    return (
        <>
            {JSON.stringify(data) !== '{}' &&
                <div className="list-room" style={{ paddingTop: '30px', width: '200px' }}>
                    <div className="list-room_title" style={{}}>
                        <div className="input-group-prepend" style={{ fontSize: '1.3rem', width: '200px' }}>
                            <button className="btn btn-outline-secondary dropdown-toggle"
                                style={{ width: '200px', fontSize: '1.3rem', color: 'white' }}
                                type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">VIEW All TYPE</button>
                            <div className="dropdown-menu" style={{ backgroundColor: '#999' }}>
                                {renderListRoomType()}
                            </div>
                        </div>
                    </div>
                    <div className="" >
                        {renderRoomType()}
                    </div>
                    <div style={{background:'orange', fontSize:'1.5rem', color:'white',padding: '7px'}}>WAITING LIST</div>
                    {/* <div className="list-room_list-room bg_pink_color" style={{ width: '100%' }}>Check In</div>
                    <div className="list-room_list-room bg_pink_color" style={{ width: '100%' }}>Check Out</div>
                    <div className="list-room_list-room bg_pink_color" style={{ width: '100%' }}>Total Room</div>
                    <div className="list-room_list-room bg_pink_color" style={{ width: '100%' }}>Capacity</div> */}
                    {renderRowNameWithList(listWaiting, "waiting")}
                    <div style={{background:'#F06292', fontSize:'1.5rem', color:'white',padding: '7px'}}>CANCEL LIST</div>
                    {renderRowNameWithList(listCancel, "cancel")}
                </div>
            }
        </>
    );
}

export default ReservationRoomStyleComponent;
